import vtulEnums from '../../../cross/index';

const { deviceStatus } = vtulEnums.enum.device;
const { amtStatus } = vtulEnums.enum.amt;
export default class ColorStatus {
  static getAMTColorStatus(status) {
    const enumStatus = Object.values(amtStatus).find((x) => x.value && x.value.dbValue === status);
    return enumStatus.value.color;
  }

  static getAgentColorStatus(status) {
    const enumStatus = Object.values(deviceStatus).find(
      (x) => x.value && x.value.dbValue === status,
    );
    return enumStatus.value.color;
  }
}
