import ExecutionMixin from '@/mixins/executions';
import EnvironmentSetter from '@/helpers/environmentSetter';
import executionsEnums from '../../../cross/src/execution.enum';
import snackBarMessage from '../helpers/snackBarMessage';

export default {
  name: 'AgentUpdatedMixin',
  data() {
    return {
      agentLastVersion: '0.0.0',
    };
  },
  created() {
    this.getAgentLastVersion();
  },
  mixins: [ExecutionMixin],
  methods: {
    async updateAgent(agentAction) {
      const execution = await this.createExecutions(
        agentAction.companyId,
        agentAction.userId,
        [agentAction.deviceId],
        executionsEnums.executeAction.UPDATE_AGENT.value.id,
        { onlyOnlineDevices: true },
      );

      const router = this.$router;
      snackBarMessage.showSuccess('We are updating the Boardgent Agent.', {
        text: 'See execution',
        run: () => { router.push(`/${agentAction.companyId}/execution/${execution.executionGroupId}/1`); },
      });
    },
    async getAgentLastVersion() {
      const vtulAgentServerUrl = EnvironmentSetter.getEnvironmentByIndex('VUE_APP_AGENT');
      if (!vtulAgentServerUrl) return false;
      try {
        const url = `${vtulAgentServerUrl}?channel=latest&platform=win32&arch=x64`;
        const response = await this.axios.get(url);
        if (response.status === 200) {
          this.agentLastVersion = response.data.version.agentVersion;
        }
      } catch (error) {
        return false;
      }
      return false;
    },
  },
};
